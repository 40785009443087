// Slovak
export default{
    form: {
        startSurveyButton: "Začnime",
        nextButton: "Ďalej",
        submitAnswerButton: "Odoslať odpoveď",
        continueButton: "Pokračovať",
        finishButton: "Dokončiť",
        headerPercentageCompletion: 'dokončené',
        headerQuestionsCompleted: 'otázky',
        headerDefaultErrorMessage: 'Ups! Niečo sa pokazilo.',
        brandingPoweredBy: 'Poháňané',
        brandingPromoWhySurvey: 'Prečo robiť prieskum, keď môžete použiť <b>{product}</b>?',
        brandingPromoGetStarted: 'Začnite ešte dnes!',
        submissionPending: "Váš voiceform sa odosiela. Nezatvárajte prosím okno prehliadača.",
        submissionCanCloseWindow: "Váš voiceform bol úspešne odoslaný. Teraz môžete zavrieť okno 👍",
        mediaAttachmentOverlayCloseButton: "Zavrieť",
        expiredFormHeader: "Ďakujeme, že ste sa zastavili!",
        expiredFormMessage: "Zdá sa, že tento prieskum vypršal. Kontaktujte prosím tvorcu voiceformu a informujte ho.",
        notPublishedFormHeader: "Voiceform nie je zverejnený!",
        notPublishedFormMessage: "Zdá sa, že tento prieskum nebol zverejnený. Kontaktujte prosím tvorcu voiceformu a informujte ho.",
        notPublishedFormRetryButton: "Skúsiť znova",
        errorFormHeader: "Oops!",
        errorFormMessage: "Niečo sa mohlo pokaziť. Skúste to prosím neskôr.",
        errorFormRetryButton: "Skúsiť znova",
        emptyFormHeader: "Ďakujeme, že ste sa zastavili!",
        emptyFormMessage: "Ups! Zdá sa, že tento formulár je momentálne prázdny, bez otázok alebo polí na vyplnenie. Ospravedlňujeme sa za nepríjemnosti. Kontaktujte prosím tvorcu formulára a informujte ho o tomto probléme. Ďakujeme za pochopenie a spoluprácu!",

        restoreFormHeader: "Zdá sa, že máte formulár v procese!",
        restoreFormMessage: "Chcete pokračovať tam, kde ste skončili?",
        restoreFormButton: "Pokračovať vo formulári",
        restoreFormButtonNew: "Začať odznova",

        audioPermissionHeader: "Chcete odpovedať hlasom?",
        audioPermissionMessage: "Hovorenie uľahčí a urýchli odpoveď.",
        audioPermissionAcceptButton: "Áno, chcem",
        audioPermissionDenyButton: "Nie, ďakujem",
        audioPermissionErrorHeader: "Mikrofón nie je dostupný",
        audioPermissionErrorMessage: "Skontrolujte prosím oprávnenia prehliadača na povolenie prístupu k mikrofónu.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Nepodarilo sa nahrať záznam",
        silenceDetectorError: "Nepočuť vás. Skontrolujte prosím nastavenia mikrofónu. Tip: Môže to byť váš externý mikrofón.",
        audioRecorderButtonRecord: "Začať nahrávať",
        audioRecorderButtonRecordMore: "Nahrať viac",
        voiceResponseSelectionMessage: "Vyberte, ako chcete odpovedať...",
        voiceResponseSelectionOrDivider: "alebo",
        voiceResponseTextInputPlaceholder: "Napíšte odpoveď",
        voiceResponseTextInputCharacterCounterMinMessage: "Zadajte prosím aspoň {min} znakov",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} znakov (minimálne {min} znakov)",
        voiceResponseTextInputCharacterValidationMessage: "{count} znakov (minimálne {min} znakov)",

        // checkbox
        checkboxValidationTooFew: "Musíte vybrať aspoň {min} možnosť(i)",
        checkboxValidationTooMany: "Musíte vybrať najviac {max} možnosť(i)",
        checkboxNoneOfTheAboveOption: "Žiadna z uvedených možností",
        checkboxInvalidAnswer: "Neplatná odpoveď.",

        // datepicker
        datePickerPlaceholder: "Vyberte dátum",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Neplatný e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Nahrať video",
        fileUploadPluginScreenCast: "Nahrávanie obrazovky",
        fileUploadVideoImportFilesDefault: 'Nahrajte alebo odovzdajte video cez:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Vyberte možnosť nahrávania videa:',
        fileUploadVideoImportFilesNoCamera: 'Stlačte tlačidlo pre nahrávanie obrazovky',
        fileUploadVideoImportFilesNoScreenCast: 'Stlačte tlačidlo pre nahrávanie videa',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nie sú dostupné žiadne možnosti pre nahrávanie videa',

        // matrix
        matrixValidationMessage: "Odpoveď je platná.",
        matrixRow: "Riadok",
        matrixColumn: "Stĺpec",
        matrixRowRequired: "Riadok {rowTitle} je povinný.",
        matrixRadioGroup: "Rádio skupina",
        matrixCheckboxGroup: "Skupina zaškrtávacích políčok",
        matrixGroupRequired: "Pre riadok {rowTitle}. {type} {groupTitle} je povinný.",
        matrixColumnRequired: "Pre riadok {rowTitle}. Stĺpec {columnTitle} je povinný.",
        matrixColumnInvalid: "Pre riadok {rowTitle}. Stĺpec {columnTitle} je neplatný.",
        matrixRequired: "Povinné.",
        matrixNumericMustBeNumber: "Musí to byť číslo.",
        matrixNumericMustBeGreaterThenMin: "Musí byť väčšie ako {min}.",
        matrixNumericMustBeLessThenMax: "Musí byť menšie ako {max}.",
        matrixNumericMustBeInteger: "Musí to byť celé číslo.",
        matrixNumericInvalidNumber: "Neplatné číslo.",

        // name
        nameLabel: "Meno",
        nameInvalid: "Uveďte meno a priezvisko.",

        // nps
        npsNotLikely: "Vôbec nie pravdepodobné",
        npsExtremelyLikely: "Veľmi pravdepodobné",

        // numeric input
        numericInputRequired: "Povinné.",
        numericInputMustBeNumber: "Musí to byť číslo.",
        numericInputMustBeGreaterThenMin: "Musí byť väčšie ako {min}.",
        numericInputMustBeLessThenMax: "Musí byť menšie ako {max}.",
        numericInputMustBeInteger: "Musí to byť celé číslo.",
        numericInputInvalidNumber: "Neplatné číslo.",
        numericInputPlaceholder: "Zadajte číslo",

        // phone
        phoneInvalid: "Telefónne číslo je neplatné.",
        phoneCountrySelectorLabel: 'Kód krajiny',
        phoneCountrySelectorError: 'Vyberte krajinu',
        phoneNumberLabel: 'Telefónne číslo',
        phoneExample: 'Príklad:',

        // boolean
        booleanYesLabel: "Áno",
        booleanNoLabel: "Nie",

        //questionStep
        questionStepAudioQuestionLabel: "Audio otázka",

        // errors
        invalidPhoneNumber: "{phone} je neplatné telefónne číslo.",
        invalidEmail: "{email} je neplatná e-mailová adresa.",
        questionIsRequired: "Otázka je povinná.",
        answerIsNotValid: "Odpoveď nie je platná.",
        unfinishedProbingDialogError: "Dokončite prosím dialóg.",
        cannotResumePartialResponse: "Nie je možné pokračovať v čiastočnej odpovedi.",
        failedToSubmitForm: "Nepodarilo sa odoslať formulár. Skontrolujte internetové pripojenie a skúste to znova."
    }
}
